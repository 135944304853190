<template>
  <div class="proyecto_carousel" @click="handleClick">
    <b-img class="proyecto_img" :src="proyecto.galeria[0].url" />
    <div class="detalle_wrap">
      <h5>
        {{ localeCode === "es" ? proyecto.nombre : proyecto.nombreIngles }}
      </h5>
      <hr />
      <b-link class="text-project">
        {{ text }}
        <b-img :src="require('@/assets/img/svg/ico-plus.svg')" />
        >
      </b-link>
    </div>
  </div>
</template>

<script>
import router from "../../router";
import { mapGetters } from "vuex";
import { EventBus } from '../../main';

export default {
  props: {
    proyecto: Object,
    position: String,
    tipo: String,
  },
  data() {
    return {
      router,
    };
  },
  computed: {
    ...mapGetters({
      localeCode: "locale/localeCode",
    }),
    text() {
      if (this.tipo === "propiedad") {
        return this.$t("common.view_property");
      }
      return this.$t("common.view_project");
    },
  },
  methods: {
    handleClick() {
      if (this.$route.name == "Home") {
        return this.router.push("proyectos");
      }
      this.changeProyecto();
    },
    changeProyecto() {

      EventBus.$emit('change-section')

      if (this.tipo == "proyecto") {
        this.$store.commit("proyectos/SET_PROYECTO_ACTIVO", this.proyecto.id);
      }
      if (this.tipo == "propiedad") {
        this.$store.commit(
          "propiedades/SET_PROPIEDAD_ACTIVO",
          this.proyecto.id
        );
      }
      if (this.tipo == "comercializacion") {
        this.$store.commit(
          "comercializacion/SET_PROYECTO_ACTIVO",
          this.proyecto.id
        );
        console.log("here");
      }
    },
  },
};
</script>

<style scoped>
.text-project {
  font-size: 1.2rem !important;
}
.proyecto_carousel {
  cursor: pointer;
}
</style>

<style>
.slick-slide {
  margin: 0 5px !important;
}
</style>